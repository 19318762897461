

<div class="page-padding">
    <div class="container-large">
        <div class="eco-calculator-main-wrapper">
            <div class="animation-detail-main-card-full-wrapper">
                <div class="animation-detail-main-card-section">
                    <div class="animation-detail-main-card-top-wrapper">
                        <div class="animation-card-btn-wrapper">
                            <button class="eco-counter-button" (click)="setType('IMPACT_CALC')" [ngClass]="{'clicked': isImpactButtonClicked}">Impact Calculator</button>
                            <button class="eco-counter-button" (click)="setType('OUR_IMPACT')" [ngClass]="{'clicked': isOurButtonClicked}">Our Impact</button>
                        </div>
                        <div class="zsec-counter-wrapper">
                            <input *ngIf="type==1" mask="separator.0" maxlength="7" thousandSeparator="," [(ngModel)]="ourDocCount"  (input)="adjustWidth($event)" class="zsec-pages-input-wrapper"/>
                            <input *ngIf="type==0" [readonly]="true" [ngModel]="docCountString" mask="separator.0"  thousandSeparator="," [value]="docCountString"  (input)="handleSliderChange($event, true)" class="zsec-pages-input-wrapper" appNumericInput type="text" />
                            <div class="sheet-of-paper-wrapper">Sheets of paper saved</div>
                        </div>
                    </div>
                    <div class="zsec-content-bottom-section">
                        <div class="zsec-content-input-section">
                            <!-- <div class="zsec-slider-wrapper"> -->
                                <!-- <div class="zsec-content-heading" *ngIf="type==0">ZorroSign's positive impact on the environment.</div>
                                <div class="zsec-content-heading" *ngIf="type==1">Measure your impact by dragging the slider</div> -->
                                <input [style.background]="getLinearGradient()" *ngIf="type==1" type="range" id="rangeSlider" min="0"
                                    [max]="MAX_DOC_COUNT" [(ngModel)]="ourDocCount" class="range" (input)="handleSliderChange($event)" />
                            <!-- </div> -->
                        </div>
                
                        <div class="zsec-content-top-section">
                            <div *ngIf="type==1" class="zsec-content-cards-section">
                                <div class="zsec-first-card-wrapper">
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-tree.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value" id="counter">{{ ourDocCount.toString()|
                                                calculateEnvironmentalImpact:'treesSaved' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                trees saved
                                            </div>
                                        </div>
                                    </div>
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-water.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">{{ ourDocCount.toString() |
                                                calculateEnvironmentalImpact:'waterSaved' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                water saved (gal)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="zsec-second-card-wrapper">
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-co2.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">{{ ourDocCount.toString() |
                                                calculateEnvironmentalImpact:'co2Emission' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                CO<sub>2</sub> avoided (kg)
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-cost.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">
                                                {{ ourDocCount.toString() |
                                                calculateEnvironmentalImpact:'costSaving' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                cost saved ($)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="type==0" class="zsec-content-cards-section">
                                <div class="zsec-first-card-wrapper">
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-tree.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value" id="counter">{{ documentCount.toString()|
                                                calculateEnvironmentalImpact:'treesSaved' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                trees saved
                                            </div>
                                        </div>
                                    </div>
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-water.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">{{ documentCount.toString() |
                                                calculateEnvironmentalImpact:'waterSaved' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                water saved (gal)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="zsec-second-card-wrapper">
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-co2.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">{{ documentCount.toString() |
                                                calculateEnvironmentalImpact:'co2Emission' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                CO<sub>2</sub> avoided (kg)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="zsec-content-card-wrapper">
                                        <div class="zsec-content-card-top-wrapper">
                                            <img src="../../../assets/card-cost.svg" class="zsec-content-card-top-wrapper-img" alt="">
                                            
                                        </div>
                                        <div class="zsec-content-cards-detail-wrapper">
                                            <div class="zsec-content-card-value">{{ documentCount.toString() |
                                                calculateEnvironmentalImpact:'costSaving' }}
                                            </div>
                                            <div class="zsec-content-card-name">
                                                cost saved ($)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        
            
            <!-- [documentCount]="documentCount" [type]="type" -->
            <ng-container >
                <div class="eco-counter-content-wrapper">
                    <div class="zsec-content-main-section">
                        
                        <div class="zsec-content-animation-section">
                            <div class="copyright-text-wrapper">
                                <p class="copyrightText">© 2024 Zafer, Inc. All rights reserved.</p>
                              </div>
                            <!-- Static images -->
                            <img src="../../../assets/Impact Calculator V2/Background - Yellow.svg" class="layer-static layer-static1" alt="">
                            <img src="../../../assets/Impact Calculator V2/Clouds.svg" class="layer-static layer-static2" alt="">
                            <img src="../../../assets/Impact Calculator V2/Mountains.svg" class="layer-static layer-static3" alt="">
                            <img src="../../../assets/Impact Calculator V2/Forest - Yellow.svg" class="layer-static layer-static4" alt="">
                            <img src="../../../assets/Impact Calculator V2/Factory.svg" class="layer-static layer-static5" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills - Yellow.svg" class="layer-static layer-static6" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 01-Trunk.svg" class="layer-static layer-static7" alt="">
                            
        
                            <!--Dynamic images from start to end -->
                            <img src="../../../assets/Impact Calculator V2/Tree 01-Leaves 04.svg" class="layer layer1" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 01-Leaves 03.svg" class="layer layer2" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 01-Leaves 02.svg" class="layer layer3" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 01-Leaves 01.svg" class="layer layer4" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Tree 02-Trunk.svg" class="layer layer5" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 02-Leaves 04.svg" class="layer layer6" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 02-Leaves 03.svg" class="layer layer7" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 02-Leaves 02.svg" class="layer layer8" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 02-Leaves 01.svg" class="layer layer9" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Tree 03-Trunk.svg" class="layer layer10" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 04-Trunk.svg" class="layer layer11" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 03-Leaves 03.svg" class="layer layer12" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 04-Leaves 04.svg" class="layer layer13" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 03-Leaves 02.svg" class="layer layer14" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 04-Leaves 03.svg" class="layer layer15" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 03-Leaves 01.svg" class="layer layer16" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 04-Leaves 02.svg" class="layer layer17" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Tree 05-Trunk.svg" class="layer layer18" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 04-Leaves 01.svg" class="layer layer19" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 06-Trunk.svg" class="layer layer20" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 05-Leaves 03.svg" class="layer layer21" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 06-Leaves 03.svg" class="layer layer22" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 05-Leaves 02.svg" class="layer layer23" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 06-Leaves 02.svg" class="layer layer24" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 05-Leaves 01.svg" class="layer layer25" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Tree 07-Trunk.svg" class="layer layer26" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 06-Leaves 01.svg" class="layer layer27" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 08-Trunk.svg" class="layer layer28" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 07-Leaves 04.svg" class="layer layer29" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 08-Leaves 04.svg" class="layer layer30" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 07-Leaves 03.svg" class="layer layer31" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 08-Leaves 03.svg" class="layer layer32" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 07-Leaves 02.svg" class="layer layer33" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 08-Leaves 02.svg" class="layer layer34" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 07-Leaves 01.svg" class="layer layer35" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Tree 09-Trunk.svg" class="layer layer36" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 08-Leaves 01.svg" class="layer layer37" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 09-Leaves 03.svg" class="layer layer38" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 09-Leaves 02.svg" class="layer layer39" alt="">
                            <img src="../../../assets/Impact Calculator V2/Tree 09-Leaves 01.svg" class="layer layer40" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Bushes 02-01.svg" class="layer layer41" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 02-02.svg" class="layer layer42" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 03-01.svg" class="layer layer43" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 03-02.svg" class="layer layer44" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Bushes 04-01.svg" class="layer layer45" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 04-02.svg" class="layer layer46" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 04-03.svg" class="layer layer47" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Bushes 01-01.svg" class="layer layer48" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 01-02.svg" class="layer layer49" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bushes 01-03.svg" class="layer layer50" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-01.svg" class="layer layer51" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-02.svg" class="layer layer52" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-03.svg" class="layer layer53" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-04.svg" class="layer layer54" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-05.svg" class="layer layer55" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-06.svg" class="layer layer56" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-07.svg" class="layer layer57" alt="">
                            <img src="../../../assets/Impact Calculator V2/Hills-Green-08.svg" class="layer layer58" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Forest - Green.svg" class="layer layer59" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Background - Blue.svg" class="layer layer60" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Deer.svg" class="layer layer61" alt="">
        
                            <img src="../../../assets/Impact Calculator V2/Bird 01.svg" class="layer layer62" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 02.svg" class="layer layer63" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 03.svg" class="layer layer64" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 04.svg" class="layer layer65" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 05.svg" class="layer layer66" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 06.svg" class="layer layer67" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 07.svg" class="layer layer68" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 08.svg" class="layer layer69" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 09.svg" class="layer layer70" alt="">
                            <img src="../../../assets/Impact Calculator V2/Bird 10.svg" class="layer layer71" alt="">
        
                            
        
        
                            <!-- <img src="../../../assets/Impact Calculator V2/Mountains.svg" class="layer layer1" alt=""> -->
                            <!-- <img src="../../../assets/44. Tree 09 - Leaves 04.svg" data-speed="0.1" class="layer layer2" alt="">
                            <img src="../../../assets/43. Tree 09 - Leaves 03.svg" data-speed="0.1" class="layer layer3" alt="">
                            <img src="../../../assets/42. Tree 09 - Leaves 02.svg" data-speed="0.1" class="layer layer4" alt="">
                            <img src="../../../assets/40. Tree 09 - Leaves 01.svg" data-speed="0.1" class="layer layer5" alt="">
                            <img src="../../../assets/25. Tree 05 - Leaves 03.svg" data-speed="0.1" class="layer layer6" alt="">
                            <img src="../../../assets/24. Tree 05 - Leaves 02.svg" data-speed="0.1" class="layer layer7" alt="">
                            <img src="../../../assets/22. Tree 05 - Leaves 01.svg" data-speed="0.1" class="layer layer8" alt="">
                            <img src="../../../assets/32. Tree 07 - Trunk.svg" class="layer layer9" alt="">
                            <img src="../../../assets/36. Tree 08 - Trunk.svg" class="layer layer10" alt="">
                            <img src="../../../assets/34. Tree 07 - Leaves 03.svg" class="layer layer11" alt="">
                            <img src="../../../assets/39. Tree 08 - Leaves 04.svg" class="layer layer12" alt="">
                            <img src="../../../assets/33. Tree 07 - Leaves 02.svg" class="layer layer13" alt="">
                            <img src="../../../assets/38. Tree 08 - Leaves 03.svg" class="layer layer14" alt="">
                            <img src="../../../assets/31. Tree 07 - Leaves 01.svg" class="layer layer15" alt="">
                            <img src="../../../assets/37. Tree 08 - Leaves 02.svg" class="layer layer16" alt="">
                            <img src="../../../assets/27. Tree 06 - Trunk.svg" class="layer layer17" alt="">
                            <img src="../../../assets/35. Tree 08 - Leaves 01.svg" class="layer layer18" alt="">
                            <img src="../../../assets/30. Tree 06 - Leaves 04.svg" class="layer layer19" alt="">
                            <img src="../../../assets/29. Tree 06 - Leaves 03.svg" class="layer layer20" alt="">
                            <img src="../../../assets/14. Tree 03 - Trunk.svg" class="layer layer21" alt="">
                            <img src="../../../assets/28. Tree 06 - Leaves 02.svg" class="layer layer22" alt="">
                            <img src="../../../assets/17. Tree 03 - Leaves 04.svg" class="layer layer23" alt="">
                            <img src="../../../assets/26. Tree 06 - Leaves 01.svg" class="layer layer24" alt="">
                            <img src="../../../assets/16. Tree 03 - Leaves 03.svg" class="layer layer25" alt="">
                            <img src="../../../assets/19. Tree 04 - Trunk.svg" class="layer layer26" alt="">
                            <img src="../../../assets/15. Tree 03 - Leaves 02.svg" class="layer layer27" alt="">
                            <img src="../../../assets/21. Tree 04 - Leaves 03.svg" class="layer layer28" alt="">
                            <img src="../../../assets/13. Tree 03 - Leaves 01.svg" class="layer layer29" alt="">
                            <img src="../../../assets/20. Tree 04 - Leaves 02.svg" class="layer layer30" alt="">
                            <img src="../../../assets/04. Tree 01 - Trunk.svg" class="layer layer31" alt="">
                            <img src="../../../assets/18. Tree 04 - Leaves 01.svg" class="layer layer32" alt="">
                            <img src="../../../assets/09. Tree 02 - Trunk.svg" class="layer layer33" alt="">
                            <img src="../../../assets/07. Tree 01 - Leaves 04.svg" class="layer layer34" alt="">
                            <img src="../../../assets/12. Tree 02 - Leaves 04.svg" class="layer layer35" alt="">
                            <img src="../../../assets/06. Tree 01 - Leaves 03.svg" class="layer layer36" alt="">
                            <img src="../../../assets/11. Tree 02 - Leaves 03.svg" class="layer layer37" alt="">
                            <img src="../../../assets/05. Tree 01 - Leaves 02.svg" class="layer layer38" alt="">
                            <img src="../../../assets/10. Tree 02 - Leaves 02.svg" class="layer layer39" alt="">
                            <img src="../../../assets/03. Tree 01 - Leaves 01.svg" class="layer layer40" alt="">
                            <img src="../../../assets/08. Tree 02 - Leaves 01.svg" class="layer layer41" alt="">
                            <img src="../../../assets/02. Forest - Back.svg" class="layer layer42" alt="">
                            <img src="../../../assets/45. Shrubs - Front.svg" class="layer layer43" alt=""> -->
                        </div>
                    </div>
                    <!-- <app-animate [documentCount]="documentCount" [type]="type"></app-animate> -->
                </div>
            </ng-container>
            
        </div>
    </div>
</div>
