import {
  Component,
  OnInit,
  NgZone,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { gsap } from 'gsap';
import { CountServiceService } from 'src/app/services/count-service.service';

@Component({
  selector: 'app-animate',
  templateUrl: './animate.component.html',
  styleUrls: ['./animate.component.css'],
})
export class AnimateComponent implements OnInit {
  MAX_DOC_COUNT = 999999;
  public ourDocCount = 0;
  private timeline = gsap.timeline({ paused: true });
  public sliderValue = 0;
  public counterValue = 0;
  public totalMaxCount = 0;
  isReadOnly = false;
  valuePercent: number = 0;
  inputText: String = '';
  xValue = 0;
  yValue = 0;
  ngOnDestroy: any;
  docCountString: string = "0";
  isOurButtonClicked = false; // Default page is Our Impact Page
  isImpactButtonClicked = true;
  documentCount = 0;
  documentCountResponse = 0;
  type = 1; // Default page is Our Impact Page

  constructor(private zone: NgZone, 
    private counterService: CountServiceService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    // console.log('documentCount: ', this.documentCount);
    const layers = document.querySelectorAll('.layer');
    const animationDuration = 0.5;
    const delayBetweenImages = 0.1;
    const fadeInDuration = 0.5;
    const easing = 'power1.in';

    layers.forEach((layer, index) => {
      this.timeline.fromTo(
        layer,
        { opacity: 0, x: 0 },
        { opacity: 1, duration: fadeInDuration, ease: easing },
        index * (animationDuration + delayBetweenImages)
      );
    });

    gsap.to(this, {
      duration: 2.5, 
      counterValue: this.documentCount,
      onUpdate: () => {
        // Update the counter property
        this.zone.run(() => {
          this.documentCount = Math.round(this.counterValue);
        });
      },
      x: -100,
      ease: "power2.out"
    });
    
    const progress = this.documentCount / this.MAX_DOC_COUNT;
    this.timeline.progress(progress);
    if (this.type == 1) {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
    }

    // const parallaxElements = this.el.nativeElement.querySelectorAll('.layer');

    // const handleMouseMove = (e: MouseEvent) => {
    //   const xValue = e.clientX - window.innerWidth / 2;
    //   const yValue = e.clientY - window.innerHeight / 2;

    //   console.log(xValue, yValue);

    //   parallaxElements.forEach((el: HTMLElement) => {
    //     const speedx = parseFloat(el.dataset.speedx || '1');
    //     // el.style.transform = `translate(calc(-50% + ${-xValue * speedx}px)) translateY(calc(-50% + ${yValue}px))`;
    //     el.style.transform = `translateX(calc(-0% + ${-xValue * speedx}px))`;

    //     // el.style.transform = `translate(${xValue * speedx}px) translateY(${yValue}px)`;
    //   });
    // };

    // window.addEventListener('mousemove', handleMouseMove);

    // // Clean up the event listener when the component is destroyed
    // this.zone.runOutsideAngular(() => {
    //   const ngOnDestroyOriginal = this.ngOnDestroy.bind(this);
    //   this.ngOnDestroy = () => {
    //     window.removeEventListener('mousemove', handleMouseMove);
    //     ngOnDestroyOriginal();
    //   };
    // });

    let orgId = '';
    let profileId = '';
    this.route.queryParams.subscribe(params => {
      // Access query parameters here
      profileId = params['profileId']||''; // Replace 'parameterName' with your actual parameter name
      // console.log('Parameter profileId:', profileId);
    });
    this.route.paramMap.subscribe(params => {
      // Access query parameters here
      orgId = params.get('orgId')||''; // Replace 'parameterName' with your actual parameter name
      // console.log('Parameter Value:', orgId);
    });

    this.counterService.getDocumentCount(orgId, profileId).subscribe( (resp) => {
      // console.log(resp);
      console.log('responce',resp);
      
      if(resp && resp?.pageCount && resp?.pageCount > 0)
      this.documentCountResponse = resp?.pageCount;
    
     this.documentCount = this.documentCountResponse;
     this.ourDocCount = this.documentCount;
     this.docCountString = String(this.documentCount);
    })
  }


  handleSliderChange(event: Event, adjust= false) {
    if (adjust) {
      this.adjustWidth(event);
    }
    const value = (event.target as HTMLInputElement).value;
    this.documentCount = value ? +value.replace(/,/g, '') : 0;
    
    const progress = this.documentCount / this.MAX_DOC_COUNT;
    this.timeline.progress(progress);

    if (this.timeline.isActive()) {
      this.timeline.pause();
    }

    const mySlider = event.target as HTMLInputElement;
    this.valuePercent = (mySlider.valueAsNumber / this.MAX_DOC_COUNT) * 100;
    mySlider.style.background = `linear-gradient(to right, #104035 ${this.valuePercent}%, #EAECF0 ${this.valuePercent}%)`;
  }

  getLinearGradient() {
    let valuePercent = (this.ourDocCount / this.MAX_DOC_COUNT) * 100;
    return `linear-gradient(to right, #104035 ${valuePercent}%, #EAECF0 ${valuePercent}%)`;
  }

  adjustWidth(event: Event): void {
    const input = event.target as HTMLInputElement;
    const valueLength = input.value.length;
    const minWidth = 174;
    const baseWidth = 21;
    const maxCharacters = 7;

    if (valueLength < maxCharacters) {
      input.style.width = minWidth + 'px';
    } else {
      const extraCharacters = valueLength - maxCharacters;
      const newWidth = minWidth + extraCharacters * baseWidth;
      input.style.width = newWidth + 'px';
    }

    // Limiting the character count directly
    if (valueLength > maxCharacters) {
      input.value = input.value.slice(0, maxCharacters);
    }
  }

  // startCounterAnimation(){
  //   const counterValue = parseFloat(this.ourDocCount.toString());
  //   const counterElement = document.getElementById('counter');
    
  //   gsap.to(counterElement, {
  //     duration:2,
  //     innerHTML: counterValue,
  //     onUpdate: () => {

  //       if(counterElement){
  //         counterElement.innerText = Math.ceil(counterValue).toString();
  //       }
  //     }
  //   });
  // }

  setType(selectedType: string) {
    if(selectedType==='OUR_IMPACT') {
      this.type = 0;
      this.isOurButtonClicked = true;
      this.isImpactButtonClicked = false;
      this.documentCount = 0;
      this.documentCount = this.documentCountResponse;
    }
    else{
      this.type = 1;
      this.isOurButtonClicked = false;
      this.isImpactButtonClicked = true;
    }
  }
}
