import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CountResponse} from './../entity/CountResponse';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountServiceService {
  // private baseUrl = "https://ppgateway.entrusttitle.net"
  private baseUrl = environment.counterServiceUrl;

  constructor(private httpClient: HttpClient) { }



  getDocumentCount(orgId?: string| null, profileId?: string | null) {

    let url = "/api/v1/eco-counter-retrieve";
  
    if(orgId && orgId != '') {
      url += "/org/" + orgId;
    }
    if(profileId && profileId != '') {
      url += "/profile/" + profileId;
    }
    this.baseUrl += url;
    return this.httpClient.get<CountResponse>(this.baseUrl);
    
  }
}
