<div class="footer-section">
    <div class="page-padding">
        <div class="container-large">
            <div class="footer-content-wrapper">
                <div class="cta-wrapper">
                    <div class="cta-left-wrapper">
                        <div class="cta-text-wrapper">
                            <div class="cta-heading-text">
                                Shaping a Zafer Future
                            </div>
                            <div class="cta-description-text">
                                Experience AI and blockchain-powered data security solutions designed to save time and boost efficiency. Start your free trial today to unlock tools that enable trust in every interaction.
                            </div>
                        </div>
                        <a href="https://accounts.zorrosign.net/realms/Mercury/protocol/openid-connect/registrations?client_id=NEFELI&response_type=code&scope=openid%20email&redirect_uri=https://app.zorrosign.net/&kc_locale=EN" target="_blank" class="cta-btn-wrapper cta-btn">
                            Start free trial
                        </a>
                    
                    </div>
                    <div class="cta-right-wrapper">
                        <img src="../../../assets/Start Your Journey Illustration.png" alt="start your free trial image" class="free-trial-banner" >
                    </div>
                </div>
                <div class="footer-nav-wrapper">
                    <div class="social-links-wrapper">
                        <a href="https://www.facebook.com/ZorroSign" target="_blank" class="social-links">
                            <img src="../../../assets/Facebook.svg" alt="Facebook icon" class="social-media-icon">
                        </a>
                        <a href="https://www.instagram.com/zorrosign1/" target="_blank" class="social-links">
                            <img src="../../../assets/Instagram.svg" alt="Instagram icon" class="social-media-icon">
                        </a>
                        <a href="https://twitter.com/zorrosign" target="_blank" class="social-links">
                            <img src="../../../assets/X-platform.svg" alt="X icon" class="social-media-icon">
                        </a>
                        <a href="https://www.linkedin.com/company/zorrosign-inc." target="_blank" class="social-links">
                            <img src="../../../assets/LinkedIn.svg" alt="LinkedIn icon" class="social-media-icon">
                        </a>
                    </div>
                    
                    <div class="application-download-links-wrapper">
                        <a href="https://play.google.com/store/apps/details?id=com.zorrosign.android&pcampaignid=web_share" target="_blank" class="download-links">
                            <img src="../../../assets/playstore-banner.svg" alt="Google playstore banner" class="app-banner">
                        </a>
                        <a href="https://apps.apple.com/lk/app/zorrosign/id1670346379" target="_blank" class="download-links">
                            <img src="../../../assets/appstore-banner.svg" alt="Apple appstore banner" class="app-banner"> 
                        </a>
                    </div>
                </div>
            </div>
            <div class="copyright-info-wrapper">
                Copyright © 2024 Zafer Inc. All rights reserved.
            </div>
        </div>
    </div>
    
</div>
