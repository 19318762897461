<div class="sustainable-home-cards-section">
    <div class="page-padding">
     <div class="container-large">
         <div class="sustainable-cards-wrapper">
            <div class="card-item">
                <div class="card-image-container">
                    <img src="../../../assets/sustainableDevelopmentImg.svg" alt="sustainable development goals image">
                </div>
                <div class="card-text-container">
                    <div class="card-title">
                        Championing sustainable development
                    </div>
                    <div class="card-body-text">
                        We’re aligning ourselves with global sustainable development goals, driving efforts in environmental stewardship, social impact, and economic responsibility. By enabling a paperless life, Zafer reduces carbon emissions and helps conserve resources. We’re committed to a greener future, helping individuals and businesses operate with integrity and environmental consciousness.
                    </div>
                </div>
            </div>
            <div class="card-item">
                <div class="card-text-container">
                    <div class="card-title">
                        Save a tree, plant a future
                    </div>
                    <div class="card-body-text">
                        At Zafer, we believe every tree makes a difference. Through our “Save a Tree, Plant a Tree” commitment, each digital transaction helps reduce paper waste, preserving trees and promoting a sustainable future. For every 8,000 pages saved by our customers, Zafer plants a tree, reinforcing our dedication to reforestation and environmental health.
                    </div>
                </div>
                <div class="card-image-container">
                    <img src="../../../assets/savetreeimg.png" alt="tree planting image">
                    <div class="owner-credit-info">
                        Designed by
                        <a href="https://www.freepik.com/" target="_blank" class="freepick-link">
                            Freepik
                        </a>
                    </div>
                </div>
            </div>
         </div>
     </div>
    </div>
 </div>