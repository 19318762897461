<div class="eco-counter-home-main-wrapper">
    <app-navigation-panel></app-navigation-panel>
    <div class="eco-counter-hero-section">
        <app-home-hero></app-home-hero>
        <app-animate></app-animate>
    </div>
    <!-- <div class="eco-calculator-main-wrapper">
        <div class="counter-top-wrapper">
            <button class="eco-counter-button" (click)="setType('IMPACT_CALC')" [ngClass]="{'clicked': isImpactButtonClicked}">Impact Calculator</button>
            <button class="eco-counter-button" (click)="setType('OUR_IMPACT')" [ngClass]="{'clicked': isOurButtonClicked}">Our Impact</button>
        </div>
        <ng-container *ngIf="documentCount !== 0 && documentCount !== undefined">
            <div class="eco-counter-content-wrapper">
                <app-animate [documentCount]="documentCount" [type]="type"></app-animate>
            </div>
        </ng-container>
        
    </div> -->
    <app-home-horizontal-card-section></app-home-horizontal-card-section>
    <app-home-cards></app-home-cards>
    <app-footer></app-footer>
</div>


<!-- <div class="eco-calculator-main-wrapper">
    <div class="counter-top-wrapper">
        <button class="eco-counter-button" (click)="setType('IMPACT_CALC')" [ngClass]="{'clicked': isImpactButtonClicked}">Impact Calculator</button>
        <button class="eco-counter-button" (click)="setType('OUR_IMPACT')" [ngClass]="{'clicked': isOurButtonClicked}">Our Impact</button>
    </div>
    <ng-container *ngIf="documentCount !== 0 && documentCount !== undefined">
        <div class="eco-counter-content-wrapper">
            <app-animate [documentCount]="documentCount" [type]="type"></app-animate>
        </div>
    </ng-container>
    
</div> -->
