<div class="navbar-section">
    <div class="page-padding">
        <div class="container-large">
            <nav class="top-navbar-wrapper">
                <div class="logo-wrapper">
                    <img src="../../../assets/zafer logo.svg" alt="zafer logo" class="logo-image">
                </div>
                <div class="nav-links-wrapper">
                    <div class="zafer-link-wrapper">
                        <a href="https://www.zafer.ai" target="_blank" class="zafer-link">
                            Back to zafer.ai
                        </a>
                    </div>
                    <a href="https://accounts.zafer.net/realms/Mercury/protocol/openid-connect/registrations?client_id=NEFELI&response_type=code&scope=openid%20email&redirect_uri=https://app.zafer.net/&kc_locale=EN" class="trial-start-btn" target="_blank" class="trial-start-btn-wrapper trial-start-btn">
                            Start free trial
                    </a>
               
                </div>
            </nav>
        </div>
    </div>
</div>