import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-counter-main-page',
  templateUrl: './counter-main-page.component.html',
  styleUrls: ['./counter-main-page.component.css']
})
export class CounterMainPageComponent implements OnInit {


  constructor() {
    
   }

  ngOnInit(): void {
   

    // this.documentCount = 250000; // <- Add values
  }

  

  redirectToClose(url: string): void {
    window.location.href = url;
    window.close(); 
  }

}
