<div class="sustainable-home-hero-section">
   <div class="page-padding">
    <div class="container-large">
        <div class="hero-section-wrapper">
            <div class="hero-section-top-heading">
                <div class="category-text">
                    Sustainability
                </div>
                <div class="title-text">
                    Enabling a greener future, together
                </div>
            </div>
            <div class="home-hero-description-wrapper">
                <div class="home-hero-description-text">
                    Switching from paper to digital isn’t just smart for business it’s essential for the planet. Each time you choose Zafer to complete transactions digitally, you reduce paper waste, conserve water, and lower carbon emissions by avoiding printing, faxing, shipping, and scanning. Make an impact that saves trees, cuts costs, and helps build a sustainable future.
                </div>
            </div>
        </div>
        
    </div>
   </div>
</div>