// environmental-impact.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateEnvironmentalImpact',
  pure: true, // Set to false if the pipe is stateful
})
export class CalculateEnvironmentalImpactPipe implements PipeTransform {
  transform(pagesCountIn: string, impactType: string): any {
    let pagesCount = parseFloat(pagesCountIn.replace(/[^0-9]/g, ''));
    if (pagesCount && pagesCount != null && pagesCount > 0) {
      const pagesPerTree: number = 8000;
      const treesSaved: number = pagesCount / pagesPerTree;
      // Return the specific impact result based on the provided impact type
      switch (impactType) {
        case 'milesDriven':
          const milesDriven: number = Math.ceil(pagesCount * 1.2);
          return this.roundOffAndDisplay(milesDriven);
        case 'treesSaved':
          return this.roundOffAndDisplay(treesSaved);
        case 'woodSavedLbs':
          const woodSavedLbs: number = Math.ceil(treesSaved * 11071);
          return this.roundOffAndDisplay(woodSavedLbs);
        case 'woodSavedKg':
          const woodSavedKg: number = Math.ceil(treesSaved * 5022);
          return this.roundOffAndDisplay(woodSavedKg);
        case 'waterSaved':
          const waterSaved: number = Math.ceil(pagesCount * 3);
          return this.roundOffAndDisplay(waterSaved);
        case 'timeSaved':
          const timeSaved: number = Math.ceil(pagesCount * 0.2);
          return this.roundOffAndDisplay(timeSaved);
        case 'co2Emission':
          const co2Emission: number = (0.0068 / 12) * pagesCount * 1.2 * 1000;
          return this.roundOffAndDisplay(co2Emission);
        case 'costSaving':
          const costSaving: number = pagesCount * 6.93;
          return this.roundOffAndDisplay(costSaving);
        default:
          return this.roundOffAndDisplay(treesSaved);
      }
    } else {
      return '0';
    }
  }

  roundOffAndDisplayV2(num: number) {
    let temp = '';
    if (num > 0 && num < 999) {
      temp = Math.ceil(num).toFixed();
    } else if (num >= 1000 && num < 999999) {
      temp = (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000 && num < 999999999) {
      temp = (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000000000 && num < 999999999999) {
      temp = (num / 1000000000).toFixed(1) + 'B';
    }

    return temp.toLocaleString();
  }

  roundOffAndDisplay(num: number) {
    let temp = '';

    if (num > 0 && num < 999) {
        temp = Math.floor(num).toFixed();
    } else if (num >= 1000 && num < 999999) {
        const rounded = (num / 1000).toFixed(1);
        temp = rounded.endsWith('.0') ? rounded.slice(0, -2) + 'K' : rounded + 'K';
    } else if (num >= 1000000 && num < 999999999) {
        const rounded = (num / 1000000).toFixed(1);
        temp = rounded.endsWith('.0') ? rounded.slice(0, -2) + 'M' : rounded + 'M';
    } else if (num >= 1000000000 && num < 999999999999) {
        const rounded = (num / 1000000000).toFixed(1);
        temp = rounded.endsWith('.0') ? rounded.slice(0, -2) + 'B' : rounded + 'B';
    }else if (num >= 1000000000000 && num < 999999999999999) {
      const rounded = (num / 1000000000000).toFixed(1);
      temp = rounded.endsWith('.0') ? rounded.slice(0, -2) + 'B' : rounded + 'T';
  }

    return temp;
}

}
