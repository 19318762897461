<div class="horizontal-card-section">
    <div class="page-padding">
        <div class="container-large">
            <div class="horizontal-cards-wrapper">
                <div class="horizontal-card-item">
                    <div class="card-image-wrapper">
                        <img src="../../../assets/one tree planted.svg" alt="" class="horizontal-card-img">
                    </div>
                    <div class="card-content-wrapper">
                        <div class="card-text-wrapper">
                            <div class="card-text-title">
                                Proud Planting Partners
                            </div>
                            <div class="card-body-text">
                                Zafer is a proud member of OneTreePlanted.org. For every 8,000 pages saved by our customers, we plant a tree, furthering our commitment to protecting the planet, one tree at a time.
                            </div>
                        </div>
                        <div class="card-footer">
                            Visit OneTreePlanted.org
                            <img src="../../../assets/arrow-narrow-right.svg" alt="right arrow icon">
                        </div>
                    </div>
                </div>
                <div class="horizontal-card-item">
                    <div class="card-image-wrapper">
                        <img src="../../../assets/plant for planet.svg" alt="" class="horizontal-card-img">
                    </div>
                    <div class="card-content-wrapper">
                        <div class="card-text-wrapper">
                            <div class="card-text-title">
                                Trillion Tree Campaign
                            </div>
                            <div class="card-body-text-wrapper">
                                <div class="card-body-text">
                                    Zafer proudly supports this ‘Plant-for-the-Planet' initiative, an organization committed to global reforestation efforts in alignment with the United Nations’ sustainability goals.
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            Visit OneTreePlanted.org
                            <img src="../../../assets/arrow-narrow-right.svg" alt="right arrow icon">
                        </div>
                    </div>
                </div>
                <div class="horizontal-card-item">
                    <div class="card-image-wrapper">
                        <img src="../../../assets/cop 28 uae.svg" alt="" class="horizontal-card-img">
                    </div>
                    <div class="card-content-wrapper">
                        <div class="card-text-wrapper">
                            <div class="card-text-title">
                                Committing to COP28-Dubai
                            </div>
                            <div class="card-body-text">
                                Inspired further by this global event, Zafer is driving efforts to reduce carbon emissions, promote reforestation, and support green data centers for carbon-neutral operations.
                            </div>
                        </div>
                        <div class="card-footer">
                            Visit OneTreePlanted.org
                            <img src="../../../assets/arrow-narrow-right.svg" alt="right arrow icon">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>