import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.route.navigate(['eco-counter', 'orgId', '418b966d-d9c2-40bd-b6d9-72ee173c0692'])
  }

}
